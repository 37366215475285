import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  DiscoveryApi,
  discoveryApiRef,
  FetchApi,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { DeprecateServiceApiClient, deprecateServiceApiRef } from './api';
import { rootRouteRef } from './routes';

export const deprecateServicePlugin = createPlugin({
  id: 'deprecate-service',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: deprecateServiceApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({
        discoveryApi,
        fetchApi,
      }: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
      }) =>
        new DeprecateServiceApiClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const DeprecateServicePage = deprecateServicePlugin.provide(
  createRoutableExtension({
    name: 'DeprecateServicePage',
    component: () =>
      import('./components/DeprecateService').then(m => m.DeprecateService),
    mountPoint: rootRouteRef,
  }),
);
