/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  DiscoveryApi,
  createApiRef,
  FetchApi,
} from '@backstage/core-plugin-api';
import { NotFoundError, ResponseError } from '@backstage/errors';
import { Release } from '../types';

/**
 * Interface for the DevLakeApi.
 * @public
 */
export interface DevLakeApi {
  triggerWebhook(release: Release): Promise<any[]>;
}

/**
 * @public
 */
export const devlakeApiRef = createApiRef<DevLakeApi>({
  id: 'plugin.devlake.service',
});

/**
 * Default implementation of the DevLakeApiClient.
 * @public
 */
export class DevLakeApiClient implements DevLakeApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor({
    discoveryApi,
    fetchApi,
  }: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  private async callApi<T>(path: string, body: Release): Promise<T> {
    const apiUrl = await this.discoveryApi.getBaseUrl('devlake');
    const response = await this.fetchApi.fetch(`${apiUrl}/${path}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.ok) {
      return (await response.json()) as T;
    } else if (response.status === 404) {
      throw new NotFoundError(`No release found in path '${path}'`);
    }
    throw await ResponseError.fromResponse(response);
  }

  async triggerWebhook(release: Release): Promise<string[]> {
    const result = await this.callApi<string[]>('webhook/deployment', release);
    return result;
  }
}
