import type { ProductV1alpha1 as ProductEntity } from '@internal/plugin-rentspree-catalog-model';

import React from 'react';
import {
  EntityAboutCard,
  EntityLayout,
  EntityLinksCard,
  RelatedEntitiesCard,
} from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { entityWarningContent, techdocsContent } from './shared';

const emptyHelpLink =
  'https://www.notion.so/rentspree/How-to-Add-a-New-Domain-Product-and-Bounded-Context-cc3e5c8093a2478ab66449ddac13d83f';

export const productPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>

        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8} xs={12}>
          <RelatedEntitiesCard
            variant="gridItem"
            title="Has bounded contexts"
            entityKind="BoundedContext"
            relationType={RELATION_HAS_PART}
            emptyMessage="No bounded context is part of this product"
            columns={[
              EntityTable.columns.createEntityRefColumn({
                defaultKind: 'bounded-context',
              }),
              EntityTable.columns.createOwnerColumn(),
              EntityTable.columns.createMetadataDescriptionColumn(),
            ]}
            emptyHelpLink={emptyHelpLink}
            asRenderableEntities={(entities: Entity[]): ProductEntity[] =>
              entities as ProductEntity[]
            }
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
