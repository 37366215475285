import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  DiscoveryApi,
  discoveryApiRef,
  FetchApi,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { ReleaseNotesApiClient, releaseNotesApiRef, DevLakeApiClient, devlakeApiRef } from './api';
import { rootRouteRef } from './routes';

export const releaseNotesPlugin = createPlugin({
  id: 'release-notes',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: releaseNotesApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({
        discoveryApi,
        fetchApi,
      }: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
      }) =>
        new ReleaseNotesApiClient({
          discoveryApi,
          fetchApi,
        }),
    }),
    createApiFactory({
      api: devlakeApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({
        discoveryApi,
        fetchApi,
      }: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
      }) =>
        new DevLakeApiClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const ReleaseNotesPage = releaseNotesPlugin.provide(
  createRoutableExtension({
    name: 'ReleaseNotesPage',
    component: () =>
      import('./components/MainComponent').then(m => m.MainComponent),
    mountPoint: rootRouteRef,
  }),
);

export const ReleaseNotesContentPage = releaseNotesPlugin.provide(
  createComponentExtension({
    name: 'ReleaseNotesContentPage',
    component: {
      lazy: () =>
        import('./components/ReleaseNotesContentComponent').then(
          m => m.ReleaseNotesContentComponent,
        ),
    },
  }),
);
