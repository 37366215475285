/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  DiscoveryApi,
  createApiRef,
  FetchApi,
} from '@backstage/core-plugin-api';
import { NotFoundError, ResponseError } from '@backstage/errors';
import { ReleaseNotes, VersionDetail } from '../types';

/**
 * Interface for the ReleaseNotesApi.
 * @public
 */
export interface ReleaseNotesApi {
  listVersion(): Promise<VersionDetail[]>;
  getReleaseByVersion(version: string): Promise<ReleaseNotes[]>;
  generateReleaseNotes(version: string): Promise<any[]>;
}

/**
 * @public
 */
export const releaseNotesApiRef = createApiRef<ReleaseNotesApi>({
  id: 'plugin.release-notes.service',
});

/**
 * Default implementation of the ReleaseNotesApiClient.
 * @public
 */
export class ReleaseNotesApiClient implements ReleaseNotesApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor({
    discoveryApi,
    fetchApi,
  }: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  private async callApi<T>(path: string): Promise<T> {
    const apiUrl = await this.discoveryApi.getBaseUrl('release');
    const response = await this.fetchApi.fetch(`${apiUrl}/${path}`, {
      headers: {
        Accept: 'application/json',
      },
    });
    if (response.ok) {
      return (await response.json()) as T;
    } else if (response.status === 404) {
      throw new NotFoundError(`No release found in path '${path}'`);
    }
    throw await ResponseError.fromResponse(response);
  }

  async listVersion(): Promise<VersionDetail[]> {
    const result = await this.callApi<VersionDetail[]>('version');
    return result;
  }

  async getReleaseByVersion(version: string): Promise<ReleaseNotes[]> {
    const result = await this.callApi<ReleaseNotes[]>(`version/${version}`);
    return result;
  }

  async generateReleaseNotes(version: string): Promise<string[]> {
    const result = await this.callApi<string[]>(`version/${version}/generate`);
    return result;
  }
}
