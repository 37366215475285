import { ProductV1alpha1 as ProductEntity } from '@internal/plugin-rentspree-catalog-model';

import React from 'react';
import {
  EntityAboutCard,
  EntityHasSystemsCard,
  EntityLayout,
  RelatedEntitiesCard,
} from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { entityWarningContent } from './shared';
import { Entity, RELATION_OWNER_OF } from '@backstage/catalog-model';
import { EntityTable } from '@backstage/plugin-catalog-react';

const emptyHelpLink =
  'https://www.notion.so/rentspree/How-to-Add-a-New-Domain-Product-and-Bounded-Context-cc3e5c8093a2478ab66449ddac13d83f';

export const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <RelatedEntitiesCard
            variant="gridItem"
            title="Has products"
            entityKind="Product"
            relationType={RELATION_OWNER_OF}
            emptyMessage="No product is own by this domain"
            columns={[
              EntityTable.columns.createEntityRefColumn({
                defaultKind: 'product',
              }),
              EntityTable.columns.createMetadataDescriptionColumn(),
            ]}
            emptyHelpLink={emptyHelpLink}
            asRenderableEntities={(entities: Entity[]): ProductEntity[] =>
              entities as ProductEntity[]
            }
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
