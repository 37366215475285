import React from 'react';

import { EntitySwitch, isKind } from '@backstage/plugin-catalog';

import { componentPage } from './entity-pages/ComponentEntity';
import { apiPage } from './entity-pages/ApiEntiy';
import { groupPage } from './entity-pages/GroupEntity';
import { userPage } from './entity-pages/UserEntity';
import { systemPage } from './entity-pages/SystemEntity';
import { domainPage } from './entity-pages/DomainEntity';
import { productPage } from './entity-pages/ProductEntity';
import { defaultEntityPage } from './entity-pages/shared';
import { boundedContextPage } from './entity-pages/BoundedContextEntity';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case if={isKind('product')} children={productPage} />
    <EntitySwitch.Case
      if={isKind('boundedcontext')}
      children={boundedContextPage}
    />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
