import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'release-notes',
});

export const releaseContentRouteRef = createSubRouteRef({
  id: 'release-notes/version',
  path: '/:version',
  parent: rootRouteRef,
});
