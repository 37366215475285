import {
  CatalogKindExploreContent,
  GroupsExplorerContent,
  ExploreLayout,
} from '@backstage/plugin-explore';
import React from 'react';

export const ExplorePage = () => {
  return (
    <ExploreLayout
      title="Explore the RentSpree ecosystem"
      subtitle="Browse our ecosystem"
    >
      <ExploreLayout.Route path="squads" title="Squads">
        <GroupsExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="domains" title="Domains">
        <CatalogKindExploreContent kind="domain" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="products" title="Products">
        <CatalogKindExploreContent kind="product" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="bounded-context" title="Bounded Context">
        <CatalogKindExploreContent kind="boundedcontext" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="systems" title="Systems">
        <CatalogKindExploreContent kind="system" />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;