/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  DiscoveryApi,
  createApiRef,
  FetchApi,
} from '@backstage/core-plugin-api';
import { NotFoundError, ResponseError } from '@backstage/errors';

type DeprecateServiceParams = {
  gitlabProjectId: string;
  cortexEntityTag: string;
};

/**
 * Interface for the DeprecateServiceApi.
 * @public
 */
export interface DeprecateServiceApi {
  listServiceEntities(): Promise<ListServiceEntityResponse>;
  deprecateService(params: DeprecateServiceParams): Promise<void>;
}

/**
 * @public
 */
export const deprecateServiceApiRef = createApiRef<DeprecateServiceApi>({
  id: 'plugin.deprecate-service.service',
});

/**
 * Default implementation of the DeprecateServiceApiClient.
 * @public
 */
export class DeprecateServiceApiClient implements DeprecateServiceApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor({
    discoveryApi,
    fetchApi,
  }: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  private async callApi<T>(
    path: string,
    options?: { method?: string; body?: any },
  ): Promise<T> {
    const { method = 'GET', body } = options || {};
    const apiUrl = await this.discoveryApi.getBaseUrl('deprecate-service');
    const response = await this.fetchApi.fetch(`${apiUrl}/${path}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
      method,
    });
    if (response.ok) {
      return (await response.json()) as T;
    } else if (response.status === 404) {
      throw new NotFoundError(`No release found in path '${path}'`);
    }
    throw await ResponseError.fromResponse(response);
  }

  async listServiceEntities(): Promise<ListServiceEntityResponse> {
    const result = await this.callApi<ListServiceEntityResponse>(
      '/service-entities',
    );
    return result;
  }

  async deprecateService({
    gitlabProjectId,
    cortexEntityTag,
  }: DeprecateServiceParams): Promise<void> {
    await this.callApi<ListServiceEntityResponse>('/deprecate', {
      method: 'POST',
      body: {
        gitlabProjectId,
        cortexEntityTag,
      },
    });
  }
}
